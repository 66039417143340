import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthState } from '@app/onboarding/store/onboarding.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import * as OnboardingActions from '../../store/onboarding.actions';
import * as OnboardingSelectors from '../../store/onboarding.selector';
import { OAuthActions } from './install.service';

@Component({
  selector: 'sb-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.scss'],
  standalone: false, // eslint-disable-line
})
export class InstallComponent implements OnInit, OnDestroy {
  private _store = inject(Store);
  private _actions$ = inject(Actions);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);

  public loading$: Observable<boolean>;
  public oAuthState$: Observable<OAuthState> = null;

  public installingIntegration = false;
  public createFlowDone = false;

  private _componentDestroy$ = new Subject<void>();

  ngOnInit(): void {
    this._router.navigate([], {
      queryParams: null,
      relativeTo: this._activatedRoute,
      replaceUrl: true,
    });

    this.loading$ = this._store.pipe(select(OnboardingSelectors.selectLoading));
    this.oAuthState$ = this._store.pipe(select(OnboardingSelectors.selectOAuthState));

    this._actions$.pipe(takeUntil(this._componentDestroy$), ofType(OnboardingActions.createFlowDone)).subscribe(() => {
      this.createFlowDone = true;
    });

    // Temp redirect until this page gets relevant again ie. when we have more than just Shopify,
    // or the store select dropdown is implemented
    // Currently the reinstalls are handled on this page still, so only redirect for fresh installs
    this.oAuthState$
      .pipe(
        takeUntil(this._componentDestroy$),
        filter(
          (oAuthState) =>
            oAuthState.platform === 'shopify' &&
            (oAuthState.action === OAuthActions.FRESH_INSTALL || oAuthState.action === OAuthActions.APP_FRESH_INSTALL),
        ),
      )
      .subscribe(() => {
        this._router.navigate(['/', 'stores', 'create']);
      });
  }

  ngOnDestroy(): void {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }
}
