<sb-logo-layout-wrapper>
  <div class="tw-w-full tw-max-w-screen-md tw-ms-auto tw-me-auto">
    <h1 class="tw-text-center !tw-mb-3">Hvilken salgskanal benytter du?</h1>
    <p class="tw-text-center !tw-mb-6">Angiv hvilken platform der skal benyttes for forretningen</p>
    <div class="tw-grid tw-grid-cols-4 tw-gap-4 tw-w-full">
      @for (channel of getSalesChannels() | filterSalesChannel: (showDeprecatedPlatforms$ | async); track $index) {
        <button
          (click)="selectPlatform(channel)"
          class="tw-relative tw-box-border tw-border tw-border-white tw-rounded-2xl tw-overflow-hidden tw-transition-transform tw-p-0 tw-cursor-pointer hover:tw-scale-105"
        >
          <img [src]="getIconPath(channel)" [alt]="channel" [title]="channel" />
        </button>
      }
    </div>
  </div>
</sb-logo-layout-wrapper>
