import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DataObject, Interval, StoreCreate, SubscriptionPlans } from '@app/_shared/interfaces';
import { SnackBarService } from '@app/_shared/snack-bars/snack-bar.service';
import { updateCompany } from '@app/main-store/bootstrap.actions';
import { getCompany } from '@app/main-store/bootstrap.selector';
import { Alert } from '@app/onboarding/store/onboarding.reducer';
import { selectAlert, selectLoading, selectPlans } from '@app/onboarding/store/onboarding.selector';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import * as OnboardingActions from '../../store/onboarding.actions';

@Component({
  selector: 'sb-stores-create',
  templateUrl: './create.component.html',
  host: {
    class: 'tw-flex tw-flex-col tw-gap-2.5',
  },
  standalone: false, // eslint-disable-line
})
export class CreateComponent implements OnInit, OnDestroy {
  private _store = inject(Store);
  private _snackBarService = inject(SnackBarService);
  private _formBuilder = inject(FormBuilder);

  private _componentDestroy$ = new Subject<void>();

  public subscriptionInterval: Interval = 'month';

  public plans$: Observable<DataObject<SubscriptionPlans>[]> = this._store.pipe(
    takeUntil(this._componentDestroy$),
    select(selectPlans),
  );
  public alert$: Observable<Alert> = this._store.pipe(takeUntil(this._componentDestroy$), select(selectAlert));
  public loading$: Observable<boolean> = this._store.pipe(takeUntil(this._componentDestroy$), select(selectLoading));

  public storeFormGroup = this._formBuilder.group({
    subscription_plan_id: this._formBuilder.control<number>(null, Validators.required),
    name: ['', Validators.required],
    auto_bookkeeping_after: this._formBuilder.control<DateTime>(null, Validators.required),
    promotion_code: this._formBuilder.control<string>(null),
    is_terms_approved: this._formBuilder.control<boolean>(null, Validators.required),
  });

  ngOnInit() {
    this._store
      .select(getCompany)
      .pipe(take(1))
      .subscribe((company) => {
        this.storeFormGroup.patchValue({ is_terms_approved: !!company.attributes.is_terms_approved });
        if (!!company.attributes.is_terms_approved) {
          this.storeFormGroup.get('is_terms_approved').disable();
        }
      });
  }

  ngOnDestroy() {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  onIsYearlyChange({ checked }: MatSlideToggleChange) {
    this.subscriptionInterval = checked ? 'year' : 'month';
  }

  onSelectPlan(planId: number) {
    this.storeFormGroup.patchValue({
      subscription_plan_id: planId,
    });
  }

  onCreateStore() {
    if (this.storeFormGroup.controls.subscription_plan_id.getError('required')) {
      this._snackBarService.openFailure({
        body: 'Vælg venligst et abonnement',
      });
      return;
    }

    if (this.storeFormGroup.valid === false) {
      this._snackBarService.openFailure({
        body: 'Udfyld venligst alle felter',
      });
      return;
    }

    if (this.storeFormGroup.get('is_terms_approved').value === false) {
      this._snackBarService.openFailure({
        body: 'Accepter venligst betingelser',
      });
      return;
    }

    const storeCreate: StoreCreate = {
      ...this.storeFormGroup.getRawValue(),
      auto_bookkeeping_after: this.storeFormGroup.value.auto_bookkeeping_after
        .startOf('day')
        .toFormat('yyyy-LL-dd HH:mm:ss'),
    };

    this._store.dispatch(OnboardingActions.createStore({ store: storeCreate }));
    this._store.dispatch(updateCompany({ data: { is_terms_approved: true } }));
  }
}
