import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { first, map, Observable, tap } from 'rxjs';
import { selectSalesPlatform } from '../../store/onboarding.selector';

@Injectable({ providedIn: 'root' })
export class SalesPlatformConnectGuard {
  private _store = inject(Store);
  private _router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._store.pipe(
      select(selectSalesPlatform),
      first(),
      map((salesPlatform) => salesPlatform !== null),
      tap((salesPlatformIsSet) => {
        if (salesPlatformIsSet === false) {
          this._router.navigate(['/stores/sales-platform']);
        }
      }),
    );
  }
}
