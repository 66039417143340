import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { DataGeneric } from '@app/_interface/data';
import { OAuthActions } from '@app/onboarding/oauth/install/install.service';
import { environment } from '@env/environment';
import { firstValueFrom } from 'rxjs';

interface StateResponse {
  attributes: {
    action: OAuthActions;
    api_integration_id: number;
    category: string;
    platform: string;
    store_id: number;
  };
  id: null;
  type: 'states';
}

@Injectable({ providedIn: 'root' })
export class ShopifyConnectService {
  private _httpClient = inject(HttpClient);

  public async getShopifyConnectUrl(domain: string) {
    const headers = new HttpLaravelHeaders();
    const params = new HttpParams()
      .append('filter[url]', `${domain}.myshopify.com`)
      .append('filter[platform]', 'shopify')
      .append('filter[category]', 'webshop');
    const response = await firstValueFrom(
      this._httpClient.get<DataGeneric<null, StateResponse>>(`/api/v1/user/oauth/states`, { headers, params }),
    );

    let urlParams = new HttpParams()
      .append('action', response.data.attributes.action)
      .append('url', `${domain}.myshopify.com`);

    if (response.data.attributes.store_id) {
      urlParams = urlParams.append('store_id', response.data.attributes.store_id);
    }

    if (response.data.attributes.api_integration_id) {
      urlParams = urlParams.append('api_integration_id', response.data.attributes.api_integration_id);
    }

    return `${environment.apiLaravelUrl}/api/v1/shopify/app-install?${urlParams.toString()}`;
  }
}
