<fa-icon [icon]="['fal', 'check-circle']" size="10x" class="d-flex justify-content-center mb-5 text-primary" />

@switch (platform) {
  @case ('shopify') {
    <p class="fw-bold">
      Integrationen imellem Shopify og Storebuddy er nu
      @if (action === 'app-re-install' || action === 're-install') {
        gen
      }
      oprettet
    </p>
    <ul>
      <li>Vi har oprettet webhooks i Shopify så vi begynder at synkronisere dine ordre med Storebuddy</li>
      <li>
        Benytter du Shopify payments kan du som et tilvalg synkronisere dine udbetalinger med Storebuddy og få disse
        bogført
      </li>
      <li>
        Du kan nu oprette en integration til dit regnskabsprogram og starte med at bogføre dine salg og udbetalinger.
        Herefter kan du følge bogføringen af ordre og udbetalinger i Storebuddy app'en.
      </li>
      <li>Ved at kontakte vores support kan vi hjælpe med import af historik</li>
    </ul>
  }
  @case ('dinero') {
    <p class="fw-bold">
      Integrationen imellem Visma Dinero og Storebuddy er nu
      @if (action === 'app-re-install' || action === 're-install') {
        gen
      }
      oprettet
    </p>
    <p>
      Hvis du er klar til at opsætte dine bogføringsregler, kan du med fordel gå direkte til integrationer-siden og
      komme videre med det samme.
    </p>
  }
  @case ('onpay') {
    <p class="fw-bold">
      Integrationen imellem Onpay og Storebuddy er nu
      @if (action === 'app-re-install' || action === 're-install') {
        gen
      }
      oprettet
    </p>
    <p>
      Hvis du er klar til at opsætte dine bogføringsregler eller tilføje flere integrationer, kan du med fordel gå
      direkte til integrationer-siden og komme videre med det samme.
    </p>
  }
  @case ('economic') {
    <p class="fw-bold">
      Integrationen imellem Visma Economic og Storebuddy er nu
      @if (action === 'app-re-install' || action === 're-install') {
        gen
      }
      oprettet
    </p>
    <p>
      Hvis du er klar til at opsætte dine bogføringsregler, kan du med fordel gå direkte til integrationer-siden og
      komme videre med det samme.
    </p>
  }
  @case ('stripe') {
    <p class="fw-bold">
      Integrationen imellem Stripe og Storebuddy er nu
      @if (action === 'app-re-install' || action === 're-install') {
        gen
      }
      oprettet
    </p>
    <p>
      Hvis du er klar til at opsætte dine bogføringsregler eller tilføje flere integrationer, kan du med fordel gå
      direkte til integrationer-siden og komme videre med det samme.
    </p>
  }
  @case ('go-cardless') {
    <p class="fw-bold">
      Bankintegrationen er nu
      @if (action === 'app-re-install' || action === 're-install') {
        gen
      }
      oprettet
    </p>
    <p>
      Hvis du er klar til at opsætte dine bogføringsregler eller tilføje flere integrationer, kan du med fordel gå
      direkte til integrationer-siden og komme videre med det samme.
    </p>
  }
  @default {
    <p class="fw-bold">Din forretning er nu oprettet, og du er klar til at komme videre.</p>
    <p>
      Hvis du er klar til at opsætte dine integrationer, kan du med fordel gå direkte til integrationer-siden og komme
      videre med det samme.
    </p>
  }
}

@if ((subscriptions | sbGetSubscriptionsConfirmationUrl) === null) {
  <div class="d-flex justify-content-end mt-5">
    <a mat-button [routerLink]="['/', 'integrations', storeId]">Gå til integrationer</a>
    <a mat-flat-button color="primary" [routerLink]="['/']" class="ms-2">Gå til dashboard</a>
  </div>
}

@if ((subscriptions | sbGetSubscriptionsConfirmationUrl) !== null) {
  <div class="d-flex justify-content-end mt-5">
    <a mat-flat-button color="primary" [href]="subscriptions | sbGetSubscriptionsConfirmationUrl">
      Godkend abonnement hos Shopify
    </a>
  </div>
}
