import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, takeUntil } from 'rxjs';
import * as OnboardingActions from '../../../../store/onboarding.actions';

@Component({
  selector: 'sb-stores-create-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  standalone: false, // eslint-disable-line
})
export class StoresCreatePageComponent implements OnInit, OnDestroy {
  private _actions$ = inject(Actions);
  private _router = inject(Router);

  private _componentDestroy$: Subject<void> = new Subject();

  ngOnInit() {
    this._actions$.pipe(takeUntil(this._componentDestroy$), ofType(OnboardingActions.createFlowDone)).subscribe(() => {
      this._router.navigate(['/', 'onboarding', 'success']);
    });
  }

  ngOnDestroy(): void {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }
}
