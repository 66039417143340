<form [formGroup]="storeFormGroup">
  <div class="tw-flex tw-justify-between tw-mb-4">
    <h2 class="!tw-m-0">Vælg abonnement</h2>
    <div class="tw-flex tw-items-center">
      <span class="tw-block tw-me-2">Månedligt</span>
      <mat-slide-toggle (change)="onIsYearlyChange($event)" color="primary">Årligt</mat-slide-toggle>
    </div>
  </div>

  <sb-plans
    [subscriptionInterval]="subscriptionInterval"
    [plans]="plans$ | async"
    [selectedPlanId]="storeFormGroup.controls.subscription_plan_id.value"
    (selectPlan$)="onSelectPlan($event)"
    [loading]="loading$ | async"
  ></sb-plans>

  <h2 class="!tw-mt-12">Angiv forretningsoplysninger</h2>

  <mat-form-field appearance="outline" class="tw-w-full">
    <mat-label>Forretningsnavn</mat-label>
    <input formControlName="name" matInput />
    <mat-error>Skal udfyldes</mat-error>
  </mat-form-field>

  <mat-form-field (click)="datePicker.open()" appearance="outline" class="tw-w-full tw-mt-2">
    <mat-label>Vælg startdato for bogføring</mat-label>
    <input formControlName="auto_bookkeeping_after" matInput [matDatepicker]="datePicker" [disabled]="true" />
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-hint>DD MMM. ÅÅÅÅ</mat-hint>
    <mat-datepicker #datePicker [disabled]="false"></mat-datepicker>
    <mat-error>Skal udfyldes</mat-error>
  </mat-form-field>

  <div class="tw-flex tw-items-center tw-gap-1 tw-mt-5">
    <mat-checkbox color="primary" formControlName="is_terms_approved"> Godkend </mat-checkbox>

    <a sbLink href="https://storebuddy.dk/betingelser" target="_blank">handelsbetingelser</a>
  </div>

  <button
    mat-flat-button
    (click)="onCreateStore()"
    [disabled]="(loading$ | async) || storeFormGroup.invalid"
    [class.loading]="loading$ | async"
    color="primary"
    class="tw-w-full tw-mt-4"
  >
    Start 10 dages prøveperiode
  </button>
</form>

@if ((alert$ | async)?.message !== null) {
  <sb-alert
    variant="info"
    [message]="(alert$ | async)?.message"
    iconName="exclamation-circle"
    [link]="{ url: (alert$ | async).href, text: (alert$ | async).hrefLabel, type: 'href' }"
  />
}
