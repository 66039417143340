import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { OAuthState } from '@app/onboarding/store/onboarding.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, Observable, Subject, take, takeUntil } from 'rxjs';
import * as DineroActions from '../../../../store/dinero.actions';
import * as OnboardingActions from '../../../../store/onboarding.actions';
import * as OnboardingSelectors from '../../../../store/onboarding.selector';
import { DineroOrganization } from '../../install.service';

@Component({
  selector: 'sb-install-dinero',
  templateUrl: './dinero.component.html',
  styleUrls: ['./dinero.component.scss'],
  standalone: false, // eslint-disable-line
})
export class DineroComponent implements OnInit, OnDestroy {
  private _actions$ = inject(Actions);
  private _store$ = inject(Store);

  public organizations$: Observable<DineroOrganization[]>;
  public oAuthState$: Observable<OAuthState> = null;
  public selectedOrganizationId: string = null;
  public installingIntegration: boolean = false;

  private _componentDestroy$ = new Subject<void>();

  ngOnInit(): void {
    this.organizations$ = this._store$.pipe(
      takeUntil(this._componentDestroy$),
      select(OnboardingSelectors.selectOrganizations),
    );

    this.oAuthState$ = this._store$.pipe(
      takeUntil(this._componentDestroy$),
      select(OnboardingSelectors.selectOAuthState),
    );
  }

  ngOnDestroy(): void {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  onOrganizationSelect(organizationId: string) {
    this.selectedOrganizationId = organizationId;
  }

  async onInstallIntegration() {
    this._actions$
      .pipe(takeUntil(this._componentDestroy$), ofType(OnboardingActions.createIntegrationSuccess), take(1))
      .subscribe(() => {
        this.installingIntegration = false;
      });

    this.installingIntegration = true;
    const { apiIntegrationId } = await firstValueFrom(this.oAuthState$);
    this._store$.dispatch(
      DineroActions.updateDineroIntegration({
        integration_id: apiIntegrationId,
        platform_organization_id: this.selectedOrganizationId,
      }),
    );
  }
}
