import { Component, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ShopifyConnectService } from './shopify-connect.service';

@Component({
  selector: 'sb-shopify-connect',
  templateUrl: './shopify-connect.component.html',
  styleUrls: ['./shopify-connect.component.scss'],
  standalone: false, // eslint-disable-line
})
export class ShopifyConnectComponent {
  private _shopifyConnectService = inject(ShopifyConnectService);

  public shopifyForm = new UntypedFormGroup({
    url: new UntypedFormControl('', Validators.required),
  });

  get url() {
    return this.shopifyForm.get('url');
  }

  public async connectShopify() {
    if (this.shopifyForm.valid === false) {
      return;
    }

    const domain = this.shopifyForm.get('url').value;
    window.location.href = await this._shopifyConnectService.getShopifyConnectUrl(domain);
  }
}
