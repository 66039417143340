import { Pipe, PipeTransform } from '@angular/core';
import { DataObject, Subscription } from '@app/_shared/interfaces';

@Pipe({
  name: 'sbGetSubscriptionsConfirmationUrl',
  standalone: false, // eslint-disable-line
})
export class GetSubscriptionsConfirmationUrlPipe implements PipeTransform {
  transform(subscriptions: DataObject<Subscription>[]): string {
    return (
      subscriptions.find((subscription) => subscription.attributes.confirmation_url)?.attributes.confirmation_url ??
      null
    );
  }
}
