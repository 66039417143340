import { DOCUMENT } from '@angular/common';
import { Injectable, inject, signal } from '@angular/core';
import { environment } from '@env/environment';
import userflow from 'userflow.js';

@Injectable({
  providedIn: 'root',
})
export class UserflowService {
  private document = inject(DOCUMENT);

  private _isUserFlowAvailable = signal(false);
  public readonly isUserFlowAvailable = this._isUserFlowAvailable.asReadonly();

  constructor() {
    userflow
      .load()
      .then(() => {
        userflow.init(environment.userflowToken);
        this._isUserFlowAvailable.set(true);
      })
      .catch(() => {
        console.warn('Userflow not available');
        this._isUserFlowAvailable.set(false);
      });
  }

  identify(
    userId: string,
    attributes?: {
      name?: string;
      email?: string;
    },
  ): void {
    if (!this._isUserFlowAvailable()) {
      console.warn('Userflow not available, skipping identify()');
      return;
    }
    userflow.identify(userId, attributes);
  }

  toggleResourceCenter(): void {
    if (!this._isUserFlowAvailable()) {
      console.warn('Userflow not available, skipping toggleResourceCenter()');
      return;
    }
    userflow.toggleResourceCenter();
  }

  updateUser(attributes: { [key: string]: unknown }): void {
    if (!this._isUserFlowAvailable()) {
      console.warn('Userflow not available, skipping updateUser()');
      return;
    }
    userflow.updateUser(attributes);
  }

  startFlow(flowId: string): void {
    if (!this._isUserFlowAvailable()) {
      console.warn('Userflow not available, skipping start()');
      return;
    }
    userflow.start(flowId);
  }

  reset(): void {
    if (!this._isUserFlowAvailable()) {
      console.warn('Userflow not available, skipping reset()');
      return;
    }
    userflow.reset();
  }
}
