import { Component, OnInit, inject } from '@angular/core';
import { selectSalesPlatform } from '@app/onboarding/store/onboarding.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'sb-sales-platform-connect',
  templateUrl: './sales-platform-connect.component.html',
  styleUrls: ['./sales-platform-connect.component.scss'],
  standalone: false, // eslint-disable-line
})
export class SalesPlatformConnectComponent implements OnInit {
  private _store = inject(Store);

  public salesPlatform$ = null;

  ngOnInit() {
    this.salesPlatform$ = this._store.select(selectSalesPlatform);
  }
}
