import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureFlag } from '@app/_shared/enum/feature-flag.enum';
import { PlatformIconService } from '@app/_shared/service/platform-icon.service';
import { checkFeatureFlag } from '@app/_shared/support/rxjs-pipes/checkFeatureFlag';
import { companyFeatureFlags } from '@app/main-store/feature-flags.selectors';
import { IntegrationPlatform } from '@app/onboarding/store/integrations.service';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as OnboardingActions from '../../store/onboarding.actions';

@Component({
  selector: 'sb-sales-platform',
  templateUrl: './sales-platform.component.html',
  standalone: false, // eslint-disable-line
})
export class SalesPlatformComponent {
  private _store = inject(Store);
  private _router = inject(Router);
  private platformIconService = inject(PlatformIconService);

  public readonly shopifyInstallLink = environment.shopifyInstallLink;

  public showDeprecatedPlatforms$: Observable<boolean> = this._store
    .select(companyFeatureFlags)
    .pipe(checkFeatureFlag(FeatureFlag.ShowDeprecatedPlatforms));

  getSalesChannels() {
    let salesChannels = this.platformIconService.saleChannels();
    salesChannels.unshift('shopify');
    return salesChannels;
  }

  getIconPath(platform: string) {
    return this.platformIconService.getById(platform);
  }

  public selectPlatform(integrationPlatform: IntegrationPlatform) {
    this._store.dispatch(OnboardingActions.setIntegrationPlatform({ integrationPlatform }));

    if (integrationPlatform === 'shopify') {
      window.location.href = this.shopifyInstallLink;
    } else {
      this._router.navigate(['/stores/create']);
    }
  }
}
